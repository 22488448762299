import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const SimpleRichTextPage = ({ data: { page } }) => (
  <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
    <ContentContainer>
      <h1 className="mb-90 mt-72.5">{page.title}</h1>
      {page._rawBody && (
        <BlockContent
          blocks={page._rawBody}
          className="lg:columns-2 lg:gap-60
          prose-h2:text-h5 prose-h2:mb-24 prose-h2:mt-48
          prose-h3:text-label prose-h3:mb-6 prose-h3:mt-24
          prose-p: mb-36 [&>*:first-child]:mt-0"
        />
      )}
    </ContentContainer>
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityRichTextDocument(id: {eq: $id}) {
      id
      title
      Metadata {
        ...MetaData
      }
      body {
        children {
          marks
          text
        }
        list
        style
        _rawChildren
        _type
      }
      slug {
        current
      }
      _rawBody(resolveReferences: {maxDepth: 10})
    }
  }
`;

SimpleRichTextPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default SimpleRichTextPage;
